import React from 'react'
import Seo from '../../../components/Seo'
import BigTitle from '../../../components/BigTitle'
import DefaultSection from '../../../components/DefaultSection'
import Article from '../../../components/Article'
import TeaserSection from '../../../components/TeaserSection'
import TeaserHeader from '../../../components/TeaserHeader'
import IndividualContactSection from '../../../components/IndividualContactSection'
import NavigationLink from '../../../components/NavigationLink'


const Page = () => {

  return (
    <React.Fragment>
      <Seo title="Werkstudent im Bereich Online-Marketing und Grafikdesign (m/w/d)"
           description="Du bist auf der Suche nach einem spannenden Job in einem innovativen IT-Unternehmen? Bewirb Dich jetzt für einen Platz als Werkstudent und sammle wertvolle Erfahrungen, während du gleichzeitig Deine Finanzen etwas aufbesserst!"/>
      <BigTitle title="Stellenangebot" imageRelativePath={'Titelbild_Karriere.jpg'} className="mask-black"
                textInverted={true}
                menuInverted={true} logoInverted={true}>Du bist auf der Suche nach einem
        spannenden Werkstudenten-Job in einem innovativen IT-Unternehmen? Dann bist Du hier genau richtig.</BigTitle>

      <DefaultSection>
        <Article>
          <h1>Werkstudent im Bereich Online-Marketing und Grafikdesign (m/w/d)</h1>
          <br/>
          <h2>Deine Aufgabe</h2>
          <p>Als Werkstudent im Bereich Online-Marketing und Grafikdesign (m/w/d) unterstützt Du uns vorrangig in der
            Betreuung
            unserer Social-Media-Accounts und Pflege unserer Webseiten (BUSCHMAIS und JUG Saxony). Zudem hilfst Du uns
            bei der
            Erstellung von Grafiken und dem Schreiben von Texten.</p>
          <p>Neben der Tätigkeit bei BUSCHMAIS wirst Du mit der Betreuung des <NavigationLink
            to={'https://jugsaxony.org'} ariaLabel={'Webseite des JUG Saxony e. V.'} asTextLink>JUG Saxony e.
            V.</NavigationLink> zu
            tun haben, der sich zu einer unverzichtbaren Plattform für die IT-Community in Sachsen entwickelt hat. Über
            5.000
            Besucher*innen kommen regelmäßig zu unseren (Online-) Veranstaltungen, um Wissen auszutauschen und
            Networking zu betreiben.</p>
          <h2>Dein Profil</h2>
          <p>
            <ul>
              <li>Du hast gute Noten in einem Studiengang der Wirtschafts- oder Sozialwissenschaften.</li>
              <li>Du bist sicher im Umgang mit Office-Anwendungen und hast idealerweise erste Kenntnisse mit WordPress
                bzw. HTML.
              </li>
              <li>In der Vergangenheit hast Du bereits Erfahrungen mit der Adobe Creative Cloud bzw. Affinity Suite
                gesammelt.
              </li>
              <li>Neben sehr guten Deutschkenntnissen (mind. C1 Niveau) und guten Englischkenntnissen besitzt Du die
                Fähigkeit, selbstständig und sorgfältig zu arbeiten.
              </li>
              <li>Ein ausgeprägter Sinn für Ästhetik und Design macht Dich aus.</li>
              <li>Du bist ein kommunikativer und kreativer Mensch, der gerne organisiert und im Team arbeitet.</li>
            </ul>
            Die Werkstudententätigkeit sollte mind. sechs Monate, im besten Fall mind. ein Jahr, andauern. Sie kann nach
            individueller Absprache 8 bis 16 Stunden pro Woche betragen und in der vorlesungsfreien Zeit gern bis auf 40
            Stunden pro Woche erhöht werden.
          </p>
          <h2>Deine Benefits bei BUSCHMAIS</h2>
          <p>
            <ul>
              <li>Wir legen viel Wert auf die Vereinbarkeit von Privat- und Berufsleben und wissen, dass Dein Studium
                natürlich vorgeht.
              </li>
              <li>Nach individueller Absprache ist regelmäßiges Homeoffice kein Problem.</li>
              <li>Uns ist eine direkte Kommunikation enorm wichtig, weswegen die Entscheidungswege so kurz wie nur
                möglich gehalten sind.
              </li>
              <li>Auch nach Feierabend haben wir ein offenes Ohr - ganz gleich, ob privater oder beruflicher
                Gesprächsstoff.
              </li>
              <li>Langeweile kommt bei uns nicht auf, denn wir veranstalten Ausflüge und Grillabende und nehmen an
                Sportevents teil.
              </li>
            </ul>
          </p>
        </Article>
      </DefaultSection>

      <TeaserSection className="bg-level-1" anchor="bewerben">
        <TeaserHeader title="Begeistert?">
          <p>
            Das freut uns. Sende uns bitte Deine vollständigen Bewerbungsunterlagen, ggf. mit dem frühestmöglichen
            Einstiegstermin per E-Mail. Für Rückfragen stehen wir Dir gerne zur Verfügung.
          </p>
          <p>Bitte beachte, dass Bewerbungen über die E-Mail-Dienste
            Outlook und Gmail aus technischen Gründen ggf. nicht bei uns ankommen.</p>
          <p className="mt-5">
            <a className="btn btn-outline-black" href="mailto:jobs@buschmais.com"
               title="Jetzt per Mail bewerben">Jetzt per Mail bewerben</a>
          </p>
        </TeaserHeader>
      </TeaserSection>

      <IndividualContactSection personKey={'christiane'} email={'jobs@buschmais.com'} tel={'+49 351 320923 11'}/>

    </React.Fragment>
  )
}

export default Page
